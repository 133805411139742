<template>
  <VApp>
    <JwGnbHeader v-if="isPc" />
    <slot />
    <JwBottomNav v-if="!isPc" />
    <JwFooter v-if="isPc" />
  </VApp>
</template>
<script setup>
import JwGnbHeader from '@/components/common/JwGnbHeader.vue';
import JwBottomNav from '@/components/common/JwBottomNav.vue';
import JwFooter from '@/components/common/JwFooter.vue';
import { useDisplay } from 'vuetify';
const { xlAndUp } = useDisplay();
const isPc = computed(() => xlAndUp.value);
</script>
